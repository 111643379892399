import React, {useContext, useEffect, useState} from "react";
import CardBody from "../components/Card/CardBody";
import Card from "../components/Card/Card";
import Grid from "@mui/material/Grid";
import GlobalState from "../functions/GlobalStateFunctions";
import {Button, InputAdornment, TextField} from "@mui/material";
import CardHeader from "../components/Card/CardHeader";
import {makePost} from "../functions/GeneralFunctions";


export default function BuyDomain({}) {

    const [, setGlobalState] = useContext(GlobalState)

    const [offer, setOffer] = useState("")
    const [contact, setContact] = useState("")
    const [comments, setComments] = useState("")

    useEffect(() => {
        const browser = window.navigator.userAgent
        const json = JSON.stringify({browser: browser})
        makePost(setGlobalState, "/api/on-buy-domain-load", json, () => { })
    }, [])

    function onSubmit() {
        const offerMsg = "Offer:"+offer+", contact:"+contact+", comments:"+comments
        const json = JSON.stringify({offerMsg: offerMsg})
        makePost(setGlobalState, "/api/make-offer", json,
            () => {
                alert("Thank you, your offer was successfully submitted. If you would like to discuss your offer further, please contact dale@menu.co.uk")
            }, () => {
                alert("Unfortunately your offer could not be submitted. Please contact dale@menu.co.uk to discuss the offer directly.")
            })
    }

  return (
      <div>
          <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                  <div style={{padding: "20px"}}>
                      <div>Premium UK Domain & Powerful Software</div>
                      <div style={{marginTop:"15px", fontSize: "2.2rem"}}><b>menu.co.uk</b></div>
                      <div style={{marginTop:"15px"}}>is for sale</div>

                      <div style={{fontSize: "0.7rem", color: "grey", marginTop:"20px"}}>
                          <div>Secondary domains included in purchase:</div>
                          <ul style={{marginTop:"0px"}}>
                              <li>menu.cymru</li>
                              <li>menu.london</li>
                              <li>menu.wales</li>
                          </ul>
                      </div>
                      <div>Contact <a href="mailto:dale@menu.co.uk?subject=Purchase Enquiry">dale@menu.co.uk</a> or <a
                          href={"https://www.linkedin.com/in/dale-southall-3a9a3871"}>Dale through LinkedIn</a>, to
                          discuss, or make an offer now.
                      </div>
                  </div>
              </Grid>
              <Grid item xs={12} md={4} style={{paddingRight:"10px", paddingLeft:"10px"}}>
                  <Card >
                      <CardHeader>
                          Make an offer
                      </CardHeader>
                      <CardBody>
                          <TextField style={{marginTop:"5px"}} fullWidth value={offer} onChange={ e => { setOffer(e.target.value) } } InputProps={{startAdornment: <InputAdornment position="start">&pound;</InputAdornment>}} placeholder={"Your Offer"} />
                          <TextField style={{marginTop:"10px"}} fullWidth value={contact} onChange={ e => { setContact(e.target.value) } } placeholder={"Email or Phone No"} />
                          <TextField style={{marginTop:"10px"}} multiline value={comments} onChange={ e => { setComments(e.target.value) } } rows={2} fullWidth placeholder={"Comments (optional)"} />
                          <Button style={{marginTop:"10px"}} variant={"contained"} color={"success"} fullWidth onClick={onSubmit}>Make Offer</Button>
                      </CardBody>
                  </Card>
              </Grid>
              <Grid item xs={12} md={12} style={{color: "grey", fontSize: "0.8rem", fontStyle:"italic"}}>
                  <ul>
                      <li>The sophisticated, highly scalable, low deployment-cost, software, that was developed from the ground-up (+ all data).</li>
                      <li>Development resource to convert the website to your requirements can be included within an offer.</li>
                  </ul>
              </Grid>
          </Grid>
      </div>
  )
}
